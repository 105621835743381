const initialState = {
  application: null,
  applicationId: null,
  wizardStep: 0,
  wizardStepsLength: 0,
  approveDetails: null,
  agGridTableState: {
    filter: null,
    columns: null,
    search: ""
  }
}

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APPLICATION_ID':
      return {
        ...state,
        applicationId: action.applicationId
      }
    case 'SET_APPLICATION':
      return {
        ...state,
        application: {
          ...state.application,
          ...action.payload
        }
      }
    case 'CLEAR_APPLICATION':
      return {
        ...state,
        application: null
      }
    case 'SET_WIZARD_STEP':
      return {
        ...state,
        wizardStep: action.step
      }
    case 'SET_WIZARD_STEPS_LENGTH':
      return {
        ...state,
        wizardStepsLength: action.steps
      }
    case 'SET_AG_GRID_TABLE_STATE':
      return {
        ...state,
        agGridTableState: action.state
      }
    case 'SET_APPROVE_DETAILS':
      return {
        ...state,
        approveDetails: action.payload
      }
    default:
      return state;
  }
};

export default applicationReducer
